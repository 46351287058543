import React from 'react'
import { Form, Row, Col } from 'react-bootstrap';
import { PageHeading } from './../components/global-components/header/PageHeading';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SetSelectedSheet } from '../state-management/workbook/workbookSlice';
import '../main.css';

export const SheetSelection = () => {
    // Setup the page navigation.
    const navigate = useNavigate();
    const sheets = useSelector((state) => state.sheets.sheetNames);
    const selectedSheet = useSelector((state) => state.sheets.selectedSheet);
    // Setup outwards comms with redux using custom hook.
    const dispatch = useDispatch();

    const onSheetSelectHandler = (sheet) => {
        dispatch(SetSelectedSheet(sheet));
    }

    const validationSheetSelection = () => {
        if (sheets.length === 1) {
            dispatch(SetSelectedSheet(sheets[0]));
        }

        navigate('/define-sheet-information')
    }

    const isConfirmDisabled = sheets.length > 1 && !selectedSheet;

    return (
        <div className='w-1200-wrapper'>
            <div className='custom-container-constraint-left'>
                <Row>
                    <Col md={{ span: 12 }}>
                        <PageHeading label='Choose Sheet From Workbook' />
                    </Col>
                </Row>
                <Row>
                <Col md={{ span: 3 }}>
                        {
                            sheets.map((sheet, index) => {
                                return (
                                    <div key={index} className='mb-3'>
                                        <Form.Check
                                            name='sheetname'
                                            type='radio'
                                            id={sheet}
                                            label={sheet}
                                            checked={selectedSheet === sheet || sheets.length === 1}
                                            onChange={() => onSheetSelectHandler(sheet)}
                                        />
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={{ span: 3 }}>
                        <a className='btn btn-outline-primary text-uppercase' href='/'>Start Over</a>
                    </Col>
                    <Col md={{ offset: 5 }} style={{ display: 'flex', justifyContent: 'end' }}>
                        <button
                            className='btn btn-success text-uppercase'
                            onClick={validationSheetSelection}
                            disabled={isConfirmDisabled}
                        >
                            Confirm Selection
                        </button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
