import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import { PageHeading } from '../components/global-components/header/PageHeading';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomSpinner from '../components/ui-components/CustomSpinner/CustomSpinner';
import axios from 'axios';
import '../main.css';
import { Error } from '../components/global-components/error/Error';

export const DownloadOutput = () => {
    // Setup the page navigation.
    const navigate = useNavigate();
    const workbook = useSelector((state) => state.sheets);
    const [files, setFiles] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false);
    const [errorResp, setErrorResp] = useState(null);


    const handleOnRemove = (e) => {
        e.stopPropagation()
        let name = e.target.getAttribute('data-id')
        setFiles(files.filter(f => f.name !== name))
    }

    useEffect(() => {
        let bearer = `Bearer ${workbook.tokenAuth}`;

        let config = {
            headers: {
                'Authorization': `${bearer}`,
                'Content-Type': 'application/json',
            }
        }
        const body = {
            'containerName': workbook.workbookContainer,
            'fileName': workbook.workbookFileName
        }
        const fileDownloadUrl = process.env.REACT_APP_FILE_DOWNLOAD
        try {
            axios.post(fileDownloadUrl, body, config)
                .then((response) => {
                    let responseData = [
                        {
                            name: response.data.file1, url: response.data.url1
                        },
                        {
                            name: response.data.file2, url: response.data.url2
                        },
                        {
                            name: response.data.file3, url: response.data.url3
                        }
                    ]
                    setFiles(responseData);
                    setIsLoading(false);
                });
        } catch (error) {
            console.log(error)
            console.error(`fileDownload: ${error}`);
            setError(true)
            setErrorResp(error);
        }

    }, [workbook.tokenAuth, workbook.workbookContainer])

    return (
        <div className='w-1200-wrapper'>
            {error && <Error errorResponse={errorResp} />}
            <div className='custom-container-constraint-left'>
                <Row>
                    <Col md={{ span: 12 }}>
                        <PageHeading label='Download Output' />
                        <CustomSpinner isLoading={isLoading} />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>Please click the files below to download to your device</p>
                        <ul className='file-upload-container'>
                            {
                                files && files.map((f, i) => {
                                    return (
                                        <li key={i}>
                                            <div className='file-upload-content'>
                                                <p className='file-uploaded-label'>
                                                    <a style={{ textDecoration: 'none' }} href={f.url}>
                                                        <img className='file-uploaded-icon' src={`${process.env.PUBLIC_URL}/images/xls-icon.svg`} alt='Excel logo' /> {f?.name}
                                                    </a>
                                                </p>
                                                <span className='file-uploaded-remove-icon' data-id={f?.name} onClick={handleOnRemove}></span>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>
                <Row style={{ marginTop: '12rem' }}>
                    <Col md={3}>
                        <a className='btn btn-outline-primary text-uppercase' href='/'>Start Over</a>
                    </Col>
                    <Col md={{ offset: 2 }} style={{ display: 'flex', justifyContent: 'end' }}>
                        <div title='Coming Soon' style={{ cursor: 'not-allowed' }} hidden>
                            <button className='btn btn-success text-uppercase' id='btn-sampling-next' title='Coming Soon' disabled>Get Files</button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
