import React, { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { Row, Col, Form, Alert, Modal, ProgressBar } from 'react-bootstrap'
import { FileUploader } from '../components/ui-components/file-uploader/FileUploader';
import { PageHeading } from './../components/global-components/header/PageHeading';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { config } from '../components/global-components/config';
import {
    AddRMMImpact,
    AddTOCImpact,
    AddSAPImpact,
    AddOSPImpact,
    AddDATImpact,
    AddSheetList,
    AddWorkbookContainer,
    AddWorkbookFilename,
    AddWorkbookMateriality,
    AddOptionalMateriality,
    AddUuid,
    AddTestName,
    AddFinancialStatementArea,
    AddPeriodEnd,
    AddTokenAuth,
} from '../state-management/workbook/workbookSlice';
import CustomSpinner from '../components/ui-components/CustomSpinner/CustomSpinner';
import '../main.css';
import { loginRequest } from '../authConfig';
import { Error } from '../components/global-components/error/Error';

export const AttachWorkbook = () => {

    let navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const username = accounts[0] && accounts[0].username;

    const [file, setFile] = useState(null)
    const [materiality, setMateriality] = useState(null)
    const [isPerformanceMaterialityValid, setIsPerformanceMaterialityValid] = useState(false)
    const [optionalMateriality, setOptionalMateriality] = useState(null)
    const [testName, setTestName] = useState('')
    const [uuid, setUuid] = useState('')
    const [isAuditedEntityValid, setIsAuditedEntityValid] = useState(false)
    const [financialStatementArea, setFinancialStatementArea] = useState('')
    const [periodEnd, setPeriodEnd] = useState(null)
    const [isLoading, setisLoading] = useState(false)

    const [error, setError] = useState(false)
    const [errorResp, setErrorResp] = useState(null);
    const [missingDataError, setMissingDataError] = useState(null)
    const [progress, setProgress] = useState(0);
    const [showProgressPopup, setShowProgressPopup] = useState(false);

    const dispatch = useDispatch();

    const [accessToken, setAccessToken] = useState('');

    useEffect(() => {
        instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                setAccessToken(response.accessToken);
                dispatch(AddTokenAuth(response.accessToken));
            });
    }, [accounts, dispatch, instance])


    // Setup outwards comms with redux using custom hook.
    dispatch(AddRMMImpact(config.find(c => c.name === 'RMM_Impact').assessments[0].assessment));
    dispatch(AddTOCImpact(config.find(c => c.name === 'TOC_Impact').assessments[0].assessment));
    dispatch(AddSAPImpact(config.find(c => c.name === 'SAP_Impact').assessments[0].assessment));
    dispatch(AddOSPImpact(config.find(c => c.name === 'OSP_Impact').assessments[0].assessment));
    dispatch(AddDATImpact(config.find(c => c.name === 'DAT_Impact').assessments[0].assessment));

    const onFileUploaded = (file) => setFile(file);

    const ValidateUUID = () => {
        // do something.
        // console.log('validating via BDO Graph..');
    }

    const onAttachingWorksheet = async () => {

        if (materiality === null || file === null || uuid === '') {
            setMissingDataError('missing materiality, file or UUID');
            return;
        }

        const response = await postWorkbook();
        if (response) {
            dispatch(AddSheetList(response.listSheets));
            dispatch(AddWorkbookContainer(response.containerName));
            dispatch(AddWorkbookFilename(response.fileName));
            dispatch(AddWorkbookMateriality(materiality));
            dispatch(AddOptionalMateriality(optionalMateriality));
            dispatch(AddUuid(uuid));
            dispatch(AddTestName(testName));
            dispatch(AddFinancialStatementArea(financialStatementArea));
            dispatch(AddPeriodEnd(periodEnd));
            setisLoading(false);
            navigate('/sheet-selection')
        }
    }

    const postWorkbook = async () => {
        setisLoading(true);
        setShowProgressPopup(true);
        setProgress(0);

        const url = process.env.REACT_APP_FILE_UPLOAD;
        const bearer = `Bearer ${accessToken}`;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('username', `${username}`);

        const config = {
            headers: {
                Authorization: `${bearer}`,
                'Content-Type': 'multipart/form-data',
            },
            // timeout: 300000, // 5 minutes timeout
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            },
        };

        try {
            const response = await axios.post(url, formData, config);
            setShowProgressPopup(false);
            return response.data;
        } catch (error) {
            console.log(error)
            setError(true)
            setErrorResp(error);
            setShowProgressPopup(false);
            setisLoading(false);
        }
    }

    const handleMaterialityChange = (e) => {
        let value = e.target.value;
        var regex = new RegExp('^[0-9]+$');
        regex.test(value) ? setIsPerformanceMaterialityValid(true) : setIsPerformanceMaterialityValid(false);
        setMateriality(value);
    }

    const handleAuditedEntityChange = (e) => {
        let value = (e.target.value);
        (value.search(/[^a-zA-Z\s]+/) === -1 && value.length >= 1) ? setIsAuditedEntityValid(true): setIsAuditedEntityValid(false);
        setUuid(value);
    }

    return (
        <>
            {/* {showProgressPopup && (
                <Modal show centered>
                    <Modal.Body>
                        {progress < 100 ? (
                            <p>Uploading your file...</p>
                        ) : (
                            <p>Upload complete. Please wait while we analyze your file.</p>
                        )}
                        <ProgressBar now={progress} label={`${progress}%`} />
                    </Modal.Body>
                </Modal>
            )} */}
            {
                missingDataError &&
                <Row>
                    <Col style={{ padding: '0' }}>
                        {
                            // Adding basic validation and conditionally rendering components based on this.
                            (file == null || materiality == null || uuid === '') &&
                            <Alert className='text-center' style={{ borderRadius: '0' }} key='danger' variant='danger'>
                                {!uuid && <p className='m-0'>Please provide Audited Entity Name.</p>}
                                {!file && <p className='m-0'>Please upload an Excel document with xlsx extension.</p>}
                                {!materiality && <p className='m-0'>Please provide performance materiality for the workbook.</p>}
                            </Alert>
                        }
                    </Col>
                </Row>
            }
            <div className='w-1200-wrapper'>

                {error && <Error errorResponse={errorResp} />}
                <div>
                    <CustomSpinner isLoading={isLoading} />
                </div>
                <div className='custom-container-constraint-left'>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <PageHeading label='Attach Workbook' />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5}>
                            <FileUploader
                                handleFile={onFileUploaded}
                                labelForNoFiles='No workbook attached yet'
                                labelForSelectText='Select Workbook'
                                maxUploadCount={1}
                                labelForMaxNumberOfFiles='File uploaded'
                            />
                        </Col>
                        <Col ml={5}>
                            <h3 className='file-upload-instructions-heading'>Upload Instructions</h3>
                            <ul>
                                <li>Save your file as XLSX prior to uploading</li>
                                <li>Debits and Credits should be in the same transactions column</li>
                                <li>Remove formulas and #Ref values from your workbook before uploading</li>
                                <li>Remove reversing entries from the population</li>
                                <li>Ensure your file has column headings</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label htmlFor="uuid" className='font-weight-bold'>Enter Audited Entity Name</Form.Label>
                                <Form.Control id='uuid' type='text' isInvalid={!isAuditedEntityValid} placeholder='Audited Entity' lang='en' value={uuid}
                                    onChange={(e) => handleAuditedEntityChange(e)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Form.Group>
                                <Form.Label htmlFor='fsa' className='font-weight-bold'>Enter Financial Statement Area (FSA)</Form.Label>
                                <Form.Control id='fsa' type='text' lang='en' value={financialStatementArea}
                                    onChange={(e) => setFinancialStatementArea(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        {/* Commented pending Graph API functionality. See TASK: 90317
                        <Col md={2} id='validate-uuid-container'>
                            <button className='btn btn-success text-uppercase' onClick={ValidateUUID}>Validate</button>
                        </Col> */}
                    </Row>
                    <Row className='mt-3'>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label htmlFor='test-description'>Enter Test Description</Form.Label>
                                <Form.Control id='test-description' type='text' value={testName}
                                    onChange={(e) => setTestName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Form.Group>
                                <Form.Label htmlFor='materiality'>Enter Materiality</Form.Label>
                                <Form.Control id='materiality' type='text' value={optionalMateriality}
                                    onChange={(e) => setOptionalMateriality(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label htmlFor='performance-materiality'>Enter Performance Materiality</Form.Label>
                                <Form.Control id='performance-materiality' required isInvalid={!isPerformanceMaterialityValid} type='number' 
                                value={materiality} step='0.01' min='0' lang='en' onChange={(e) => handleMaterialityChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Form.Group>
                                <Form.Label htmlFor='period-end'>Period End</Form.Label>
                                <Form.Control id='period-end' type='date' value={periodEnd}
                                    onChange={(e) => setPeriodEnd(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Row className='mt-3'>
                            <Col md={{ span: 3, offset: 9 }}>
                                <button
                                    style={{ marginLeft: '7px' }}
                                    className='btn btn-success text-uppercase'
                                    id='btn-sampling-next'
                                    onClick={onAttachingWorksheet} disabled={(isLoading || !isPerformanceMaterialityValid || !isAuditedEntityValid)}>Upload Workbook</button>
                            </Col>
                        </Row>
                    </Row>
                </div>
            </div>
        </>
    )
}