import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

export const Error = ({errorResponse}) => {
    const navigate = useNavigate();
    const [showErrorMessage, setshowErrorMessage] = useState(true)
    const handleClose = () => {
        setshowErrorMessage(false)
        navigate('/')
        navigate(0);
    };
    const [redirectTimer, setRedirectTimer] = useState(30)

    useEffect(() => {
        setTimeout(updateTimer, 1000);
    }, [redirectTimer])

    const updateTimer = () => {
        let timeOut = redirectTimer - 1;
        setRedirectTimer(timeOut);
        if (timeOut <= 0) {
            handleClose();
        }
    }

    const getErrorMessage = () => {
        let error = errorResponse?.response?.data;

        if(errorResponse?.config?.url?.toLowerCase()?.endsWith('upload')){
            error = 'ERR_FILE_TOO_LARGE'
        }
     
        switch (error) {
            case 'ERR_JSON_CONVERSION':
                return 'It seems there was an issue with the file format or data conversion. Please ensure that the data in the file is in the correct format.';
            case 'ERR_FILE_TOO_LARGE':
                return 'The file you attempted to upload is too large to process.';
            default:
                return 'An unexpected error has occurred.';
        }
    };

    return (
        <Modal show={showErrorMessage} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Something went wrong.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{getErrorMessage()}</p>
                <p>You will be redirected to the home page in <span className='text-success'>{redirectTimer}</span> seconds to re-start the sampling process. Please refer to the Upload Instructions on the first page to ensure the Excel file is formatted correctly. If the error persists, please speak to a member of the support team.</p></Modal.Body>
            <Modal.Footer>
                <button
                    className='btn btn-success text-uppercase'
                    id='btn-sampling-next'
                    onClick={handleClose}>Redirect Now</button>
            </Modal.Footer>
        </Modal>
    )
}
